export default {
    home: {
        hero: {
            description: "Únete a la comunidad de entusiastas de la conducción. Comparte tus viajes, conoce nuevas personas, crea recuerdos inolvidables en la carretera y nunca vuelvas a conducir solo."
        },
        gallery: {
            title: "Paseos y Encuentros",
            subtitle: "Organizados por ti, para ti"
        },
        features: {
            title: "Nuestras Características",
            list: {
                companions: {
                    title: "Encuentra Compañeros",
                    description: "Conéctate con otros entusiastas de la conducción y comparte juntos viajes inolvidables."
                },
                messaging: {
                    title: "Mensajería Instantánea",
                    description: "Chatea en tiempo real con miembros de la comunidad para planificar tus viajes y mantenerte conectado."
                },
                events: {
                    title: "Creación de Eventos",
                    description: "Organiza y participa en paseos grupales y encuentros en tus carreteras favoritas, cerca de casa."
                },
                voting: {
                    title: "Votación Semanal",
                    description: "Inscribe tu vehículo en el concurso semanal para ganar una insignia exclusiva para tu perfil y una suscripción de por vida a Drivematch Premium."
                }
            }
        },
        comingSoon: {
            title: "Próximamente en",
            platforms: "iOS y Android",
            follow: "Síguenos en"
        },
        footer: {
            support: "Como desarrollador independiente trabajando en solitario en DriveMatch, tu apoyo me ayudará a acelerar el desarrollo de nuevas características y lanzar tu aplicación móvil más rápido.",
            rights: "© 2025 Drivematch. Todos los derechos reservados.",
            privacy: "Política de Privacidad"
        }
    },


    rules: {
        title: "Política de Privacidad",
        lastUpdate: "Última actualización: 10/02/2025",
        sections: {
            intro: {
                title: "1. Introducción",
                content: "Bienvenido a DriveMatch. Tu privacidad es importante para nosotros. Esta política explica cómo recopilamos, utilizamos y protegemos tus datos."
            },
            data: {
                title: "2. Recopilación de Datos",
                list: [
                    "Información de cuenta (nombre, correo electrónico, etc.)",
                    "Datos de ubicación (con tu consentimiento)",
                    "Mensajes e interacciones dentro de la aplicación"
                ]
            },
            usage: {
                title: "3. Uso de Datos",
                intro: "Utilizamos tus datos para:",
                list: [
                    "Mejorar tu experiencia de usuario",
                    "Garantizar la seguridad y el cumplimiento",
                    "Enviar notificaciones relevantes"
                ]
            },
            sharing: {
                title: "4. Compartir Datos",
                intro: "No vendemos tus datos. Pueden compartirse con:",
                list: [
                    "Proveedores de servicios (alojamiento, análisis, etc.)",
                    "Autoridades legales si es necesario"
                ]
            },
            security: {
                title: "5. Seguridad",
                content: "Implementamos medidas para proteger tus datos, pero ningún sistema es infalible."
            },
            rights: {
                title: "6. Tus Derechos",
                content: "Tienes derecho a acceder, modificar o eliminar tus datos. Contáctanos en"
            },
            contact: {
                title: "7. Contacto",
                content: "Si tienes alguna pregunta, contáctanos en"
            }
        },
        footer: {
            rights: "© 2025 Drivematch. Todos los derechos reservados.",
            privacy: "Política de Privacidad"
        }
    },

    error404: {
        title: "404",
        subtitle: "¡Ups! Esta página se perdió en el camino.",
        button: "Volver al Inicio"
    },
}