export default {
    home: {
        hero: {
            description: "Join the community of driving enthusiasts. Share your journeys, meet new people, create unforgettable memories on the road and never drive alone again."
        },
        gallery: {
            title: "Rides and Gatherings",
            subtitle: "Organized by you, for you"
        },
        features: {
            title: "Our Features",
            list: {
                companions: {
                    title: "Find Companions",
                    description: "Connect with other driving enthusiasts and share unforgettable journeys together."
                },
                messaging: {
                    title: "Instant Messaging",
                    description: "Chat in real-time with community members to plan your trips and stay connected."
                },
                events: {
                    title: "Event Creation",
                    description: "Organize and participate in group rides and gatherings on your favorite roads, close to home."
                },
                voting: {
                    title: "Weekly Vote",
                    description: "Enter your vehicle in the weekly contest to win an exclusive badge for your profile and a lifetime subscription to Drivematch Premium."
                }
            }
        },
        comingSoon: {
            title: "Coming soon on",
            platforms: "iOS and Android",
            follow: "Follow us on"
        },
        footer: {
            support: "As an independent developer working solo on DriveMatch, your support will help me accelerate the development of new features and launch your mobile app faster.",
            rights: "© 2025 Drivematch. All rights reserved.",
            privacy: "Privacy Policy"
        }
    },


    rules: {
        title: "Privacy Policy",
        lastUpdate: "Last update: 02/10/2025",
        sections: {
            intro: {
                title: "1. Introduction",
                content: "Welcome to DriveMatch. Your privacy is important to us. This policy explains how we collect, use and protect your data."
            },
            data: {
                title: "2. Data Collection",
                list: [
                    "Account information (name, email, etc.)",
                    "Location data (with your consent)",
                    "Messages and in-app interactions"
                ]
            },
            usage: {
                title: "3. Data Usage",
                intro: "We use your data to:",
                list: [
                    "Improve your user experience",
                    "Ensure security and compliance",
                    "Send relevant notifications"
                ]
            },
            sharing: {
                title: "4. Data Sharing",
                intro: "We don't sell your data. It may be shared with:",
                list: [
                    "Service providers (hosting, analytics, etc.)",
                    "Legal authorities if necessary"
                ]
            },
            security: {
                title: "5. Security",
                content: "We implement measures to protect your data, but no system is infallible."
            },
            rights: {
                title: "6. Your Rights",
                content: "You have the right to access, modify or delete your data. Contact us at"
            },
            contact: {
                title: "7. Contact",
                content: "If you have any questions, contact us at"
            }
        },
        footer: {
            rights: "© 2025 Drivematch. All rights reserved.",
            privacy: "Privacy Policy"
        }
    },

    error404: {
        title: "404",
        subtitle: "Oops! This page got lost along the way.",
        button: "Back to Home"
    },
}