// Error404.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavLogo from '../assets/DM_ICONE.png';

const Error404 = () => {
    const { t } = useTranslation();

    return (
        <div className="min-h-screen bg-zinc-900 flex flex-col">
            <header className="fixed w-full backdrop-blur-sm z-50 bg-zinc-900/80">
                <div className="container mx-auto px-4">
                    <nav className="flex justify-between items-center py-4">
                        <Link to="/">
                            <img
                                src={NavLogo}
                                alt="Never Drive Alone Logo"
                                className="h-16 transition-transform duration-300 hover:scale-105"
                            />
                        </Link>
                    </nav>
                </div>
            </header>

            <main className="flex-grow flex items-center justify-center">
                <div className="text-center p-6">
                    <h1 className="text-6xl font-bold text-white mb-4">{t('error404.title')}</h1>
                    <h2 className="text-2xl text-gray-300 mb-8">
                        {t('error404.subtitle')}
                    </h2>
                    <Link
                        to="/"
                        className="inline-block px-6 py-3 bg-yellow-400 text-black font-semibold rounded-full hover:bg-yellow-500 transition-colors duration-300"
                    >
                        {t('error404.button')}
                    </Link>
                </div>
            </main>
        </div>
    );
};

export default Error404;