import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Instagram } from 'lucide-react';
import NavLogo from '../assets/DM_ICONE.png';
import AppPreview from '../assets/AppPreview.png';
import RassoMoto from '../assets/RassoMoto.jpg';
import RassoVoiture from '../assets/RassoVoiture.jpg';
import RideMoto from '../assets/RideMoto.jpg';
import RideVoiture from '../assets/RideVoiture.jpg';
import FullLogo from '../assets/FullLogo.png';
import { Link } from 'react-router-dom';

const Home = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowLanguageMenu(false);
    };

    const features = [
        {
            title: t('home.features.list.companions.title'),
            description: t('home.features.list.companions.description'),
            icon: "🤝"
        },
        {
            title: t('home.features.list.messaging.title'),
            description: t('home.features.list.messaging.description'),
            icon: "💬"
        },
        {
            title: t('home.features.list.events.title'),
            description: t('home.features.list.events.description'),
            icon: "🛣️"
        },
        {
            title: t('home.features.list.voting.title'),
            description: t('home.features.list.voting.description'),
            icon: "🏆"
        }
    ];

    return (
        <div className="min-h-screen">
            <header className="fixed w-full bg-opacity-90 backdrop-blur-sm z-50">
                <div className="container mx-auto px-4">
                    <nav className="flex justify-between items-center py-4">
                        <Link to='/'>
                            <img src={NavLogo} alt="Never Drive Alone Logo" className="h-16 transition-transform hover:scale-105" />
                        </Link>

                        <div className="md:flex space-x-8 items-center">
                            <div className="relative">
                                <button
                                    onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                                    className="flex items-center space-x-2 text-white hover:text-yellow-400 transition-colors"
                                >
                                    <span className="text-xl">
                                        {i18n.language === 'fr' ? '🇫🇷' : i18n.language === 'es' ? '🇪🇸' : '🇬🇧'}
                                    </span>
                                </button>

                                {showLanguageMenu && (
                                    <div className="absolute right-0 mt-2 bg-white rounded-lg shadow-lg overflow-hidden">
                                        <button
                                            onClick={() => changeLanguage('fr')}
                                            className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 w-full"
                                        >
                                            <span className="text-xl">🇫🇷</span>
                                            <span>Français</span>
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('en')}
                                            className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 w-full"
                                        >
                                            <span className="text-xl">🇬🇧</span>
                                            <span>English</span>
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('es')}
                                            className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 w-full"
                                        >
                                            <span className="text-xl">🇪🇸</span>
                                            <span>Español</span>
                                        </button>
                                    </div>
                                )}
                            </div>

                            <a target='_blank' href="https://www.instagram.com/drivematch.app/" aria-label="Instagram" className="text-white hover:text-yellow-400 transition-colors transform hover:scale-110">
                                <Instagram size={30} />
                            </a>
                        </div>
                    </nav>
                </div>
            </header>

            <main className="pt-24">
                <div className="container mx-auto px-4 py-16">
                    <div className="flex flex-col md:flex-row items-center justify-between gap-12 h-lvh">
                        <div className="md:w-1/2 text-white">
                            <img className="mb-5" src={FullLogo} alt='Never Drive Alone' />
                            <p className="text-xl mb-8 text-gray-300 leading-relaxed">
                                {t('home.hero.description')}
                            </p>
                        </div>
                        <div className="md:w-1/2 flex justify-center">
                            <img src={AppPreview} alt="Application Preview" className="w-full max-w-md transform hover:scale-105 transition-transform duration-300" />
                        </div>
                    </div>
                </div>
            </main>

            <section id="gallery" className="container mx-auto px-4 py-16">
                <h2 className="text-4xl font-bold text-white text-center mb-5">
                    {t('home.gallery.title')}
                </h2>
                <p className="text-lg text-white text-center mb-12 font-bold">
                    {t('home.gallery.subtitle')}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="group relative overflow-hidden rounded-xl transform hover:scale-105 transition-all duration-300">
                        <img src={RassoMoto} alt="Rassemblement Moto" className="w-full h-64 object-cover" />
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75 group-hover:opacity-0 transition-opacity" />
                    </div>
                    <div className="group relative overflow-hidden rounded-xl transform hover:scale-105 transition-all duration-300">
                        <img src={RassoVoiture} alt="Rassemblement Voiture" className="w-full h-64 object-cover" />
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75 group-hover:opacity-0 transition-opacity" />
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                    <div className="group relative overflow-hidden rounded-xl transform hover:scale-105 transition-all duration-300">
                        <img src={RideMoto} alt="Ride Moto" className="w-full h-80 object-cover" />
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75 group-hover:opacity-0 transition-opacity" />
                    </div>
                    <div className="group relative overflow-hidden rounded-xl transform hover:scale-105 transition-all duration-300">
                        <img src={RideVoiture} alt="Ride Voiture" className="w-full h-80 object-cover" />
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75 group-hover:opacity-0 transition-opacity" />
                    </div>
                </div>
            </section>

            <section id="features" className="container mx-auto px-4 py-16">
                <h2 className="text-4xl font-bold text-white text-center mb-12">
                    {t('home.features.title')}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-white">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className={`p-8 rounded-xl backdrop-blur-sm transform hover:scale-105 transition-all duration-300  ${feature.title === t('home.features.list.events.title')
                                ? "bg-gradient-to-tl from-[#1F1C2C] to-[#928DAB]"
                                : feature.title === t('home.features.list.voting.title')
                                    ? "bg-gradient-to-tl from-[#000428] to-[#004E92]"
                                    : "bg-gradient-to-tl from-[#C18C00] to-[#121212] bg-opacity-80"
                                }`}
                        >
                            <div className="text-4xl mb-4">{feature.icon}</div>
                            <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                            <p className="text-white">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section id="coming-soon" className="container mx-auto px-4 py-16 text-center text-white">
                <h2 className="text-4xl font-bold mb-8">
                    {t('home.comingSoon.title')}
                    <br />
                    <span className="bg-gradient-to-r from-[#928DAB] to-[#004E92] bg-clip-text text-transparent">
                        {t('home.comingSoon.platforms')}
                    </span>
                </h2>

                <div className="flex flex-col items-center gap-8">
                    <a target='_blank' href="https://www.instagram.com/drivematch.app/" className="flex items-center gap-4 bg-black bg-opacity-30 px-6 py-3 rounded-full backdrop-blur-sm hover:text-yellow-400 transition-colors transform hover:scale-105">
                        <span className="text-lg">{t('home.comingSoon.follow')}</span>
                        <dev aria-label="Instagram">
                            <Instagram size={28} />
                        </dev>
                    </a>
                </div>


                <div className="flex flex-col items-center my-14">
                    <p className="text-gray-300 text-center mb-4 max-w-lg">
                        {t('home.footer.support')}
                    </p>
                    <a

                        href="https://www.buymeacoffee.com/drivematch"
                        target="_blank"
                        className="inline-flex items-center px-6 py-2 bg-[#FFDD00] hover:bg-[#FFDD00]/90 text-black font-semibold rounded-full transition-colors"
                    >
                        <span className="mr-2">☕</span>
                        Buy me a coffee
                    </a>
                </div>

            </section>

            <footer className="bg-zinc-900/80 border-t border-zinc-800">
                <div className="container mx-auto px-4 py-8">

                    <div className="flex justify-between items-center">
                        <p className="text-gray-400 hover:text-white transition-colors duration-300">
                            {t('home.footer.rights')}
                        </p>
                        <Link
                            to="/rules"
                            className="text-gray-400 hover:text-white transition-colors duration-300"
                        >
                            {t('home.footer.privacy')}
                        </Link>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default Home;
