export default {
    home: {
        hero: {
            description: "Rejoignez la communauté de passionnés de conduite. Partagez vos trajets, faites des rencontres, créez des souvenirs inoubliables sur la route et ne prenez plus jamais la route en solitaire."
        },
        gallery: {
            title: "Des Balades et des Rassemblements",
            subtitle: "Organisées par vous-mêmes, pour vous-mêmes"
        },
        features: {
            title: "Nos Fonctionnalités",
            list: {
                companions: {
                    title: "Trouvez des Compagnons",
                    description: "Connectez-vous avec d'autres passionnés de conduite et partagez vos trajets inoubliables ensemble."
                },
                messaging: {
                    title: "Messagerie instantanée",
                    description: "Discutez en temps réel avec les membres de la communauté pour planifier vos trajets et rester connectés."
                },
                events: {
                    title: "Création d'événements",
                    description: "Organisez et prenez part à des balades et rassemblements en groupe sur vos routes préférées, proches de chez vous."
                },
                voting: {
                    title: "Vote hebdomadaire",
                    description: "Inscrivez votre véhicule au concours hebdomadaire pour tenté de remporter un badge exclusif à votre profil ainsi qu'un abonnement à vie au Drivematch Premium."
                }
            }
        },
        comingSoon: {
            title: "Bientôt disponible sur",
            platforms: "iOS et Android",
            follow: "Suivez-nous sur"
        },
        footer: {
            support: "En tant que développeur indépendant, je travaille seul sur DriveMatch. Votre soutien m'aidera à accélérer le développement de nouvelles fonctionnalités et à lancer plus rapidement votre application mobile.",
            rights: "© 2025 Drivematch. Tous droits réservés.",
            privacy: "Règles de confidentialité"
        }

    },


    rules: {
        title: "Politique de confidentialité",
        lastUpdate: "Dernière mise à jour : 10/02/2025",
        sections: {
            intro: {
                title: "1. Introduction",
                content: "Bienvenue sur DriveMatch. Votre confidentialité est importante pour nous. Cette politique explique comment nous collectons, utilisons et protégeons vos données."
            },
            data: {
                title: "2. Données collectées",
                list: [
                    "Informations de compte (nom, e-mail, etc.)",
                    "Données de localisation (avec votre consentement)",
                    "Messages et interactions dans l'application"
                ]
            },
            usage: {
                title: "3. Utilisation des données",
                intro: "Nous utilisons vos données pour :",
                list: [
                    "Améliorer votre expérience utilisateur",
                    "Assurer la sécurité et la conformité",
                    "Envoyer des notifications pertinentes"
                ]
            },
            sharing: {
                title: "4. Partage des données",
                intro: "Nous ne vendons pas vos données. Elles peuvent être partagées avec :",
                list: [
                    "Prestataires de services (hébergement, analyses, etc.)",
                    "Autorités légales si nécessaire"
                ]
            },
            security: {
                title: "5. Sécurité",
                content: "Nous mettons en place des mesures pour protéger vos données, mais aucun système n'est infaillible."
            },
            rights: {
                title: "6. Vos droits",
                content: "Vous avez le droit d'accéder, de modifier ou de supprimer vos données. Contactez-nous à"
            },
            contact: {
                title: "7. Contact",
                content: "Si vous avez des questions, contactez-nous à"
            }
        },
        footer: {
            rights: "© 2025 Drivematch. Tous droits réservés.",
            privacy: "Règles de confidentialité"
        }
    },


    error404: {
        title: "404",
        subtitle: "Oups ! Cette page s'est perdue en route.",
        button: "Retourner à l'accueil"
    },



}