import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Rules from './pages/Rules';
import Home from './pages/Home';
import Error404 from './pages/Error404';
import './i18n';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};

export default App;