import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Instagram } from 'lucide-react';
import NavLogo from '../assets/DM_ICONE.png';
import { Link } from 'react-router-dom';

const Rules = () => {
    const { t, i18n } = useTranslation();
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowLanguageMenu(false);
    };

    return (
        <div className="min-h-screen bg-zinc-900">
            <header className="fixed w-full backdrop-blur-sm z-50 bg-zinc-900/80">
                <div className="container mx-auto px-4">
                    <nav className="flex justify-between items-center py-4">
                        <Link to='/'>
                            <img
                                src={NavLogo}
                                alt="Never Drive Alone Logo"
                                className="h-16 transition-transform duration-300 hover:scale-105"
                            />
                        </Link>

                        <div className="md:flex space-x-8 items-center">
                            <div className="relative">
                                <button
                                    onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                                    className="flex items-center space-x-2 text-white hover:text-yellow-400 transition-colors"
                                >
                                    <span className="text-xl">
                                        {i18n.language === 'fr' ? '🇫🇷' : i18n.language === 'es' ? '🇪🇸' : '🇬🇧'}
                                    </span>
                                </button>

                                {showLanguageMenu && (
                                    <div className="absolute right-0 mt-2 bg-white rounded-lg shadow-lg overflow-hidden">
                                        <button
                                            onClick={() => changeLanguage('fr')}
                                            className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 w-full"
                                        >
                                            <span className="text-xl">🇫🇷</span>
                                            <span>Français</span>
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('en')}
                                            className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 w-full"
                                        >
                                            <span className="text-xl">🇬🇧</span>
                                            <span>English</span>
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('es')}
                                            className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 w-full"
                                        >
                                            <span className="text-xl">🇪🇸</span>
                                            <span>Español</span>
                                        </button>
                                    </div>
                                )}
                            </div>


                            <a target='_blank' href="https://www.instagram.com/drivematch.app/" aria-label="Instagram" className="text-white hover:text-yellow-400 transition-colors transform hover:scale-110">
                                <Instagram size={30} />
                            </a>
                        </div>
                    </nav>
                </div>
            </header>

            <main className="pt-24 pb-16">
                <div className="p-6 max-w-3xl mx-auto text-gray-200">
                    <h1 className="text-4xl font-bold mb-4 text-white">{t('rules.title')}</h1>
                    <p className="text-sm text-gray-400 mb-8">{t('rules.lastUpdate')}</p>

                    <div className="space-y-8">
                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.intro.title')}</h2>
                            <p className="text-gray-300">{t('rules.sections.intro.content')}</p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.data.title')}</h2>
                            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
                                {t('rules.sections.data.list', { returnObjects: true }).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.usage.title')}</h2>
                            <p className="text-gray-300 mb-2">{t('rules.sections.usage.intro')}</p>
                            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
                                {t('rules.sections.usage.list', { returnObjects: true }).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.sharing.title')}</h2>
                            <p className="text-gray-300 mb-2">{t('rules.sections.sharing.intro')}</p>
                            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
                                {t('rules.sections.sharing.list', { returnObjects: true }).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.security.title')}</h2>
                            <p className="text-gray-300">{t('rules.sections.security.content')}</p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.rights.title')}</h2>
                            <p className="text-gray-300">
                                {t('rules.sections.rights.content')}{' '}
                                <span className="text-yellow-400">contact@drivematch.app</span>.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-4 text-white">{t('rules.sections.contact.title')}</h2>
                            <p className="text-gray-300">
                                {t('rules.sections.contact.content')}{' '}
                                <span className="text-yellow-400">contact@drivematch.app</span>.
                            </p>
                        </section>
                    </div>
                </div>
            </main>

            <footer className="bg-zinc-900/80 border-t border-zinc-800">
                <div className="container mx-auto px-4 py-8">
                    <div className="flex justify-between items-center">
                        <p className="text-gray-400 hover:text-white transition-colors duration-300">
                            {t('rules.footer.rights')}
                        </p>
                        <Link
                            to="/rules"
                            className="text-gray-400 hover:text-white transition-colors duration-300"
                        >
                            {t('rules.footer.privacy')}
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Rules;